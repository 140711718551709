/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Aprenda como fazer o seu cadastro completo na nossa plataforma, comece criando sua conta, ", React.createElement(_components.a, {
    href: "https://dashboard.vendoor.me/auth/signup",
    title: "clique aqui "
  }, "clique aqui"), " para ser direcionado para o link de criação de conta de ", React.createElement(_components.strong, null, "candidato.")), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/0906e4edde7acf4ff52f9a499e7b34b8/criar-conta-1-att-1.gif",
    alt: "Insira seu nome e foto de perfil"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Quando colocar sua foto de perfil lembre-se que é a imagem que o recrutador verá, portanto escolha uma foto que te evidencie."))), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/386d9c86c1033fd43cda0c6ef4ca94b8/criar-conta-2-att-1.gif",
    alt: "Insira seu whatsapp"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Criar conta\nSeu whatsapp é uma forma de ser contatado pelo recrutador, receber notificações do sistema e também poderá ser utilizado para fazer login."))), "\n", React.createElement(_components.img, {
    src: "/66f2e49802890039119df338c65284cc/criar-conta-3-1.gif",
    alt: "Insira sua data de nascimento, sexo, email e senha"
  }), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/bfdc5ecd42be72d545f829e51a8394e9/criar-conta-4-endereco-1.gif",
    alt: "Insira sua localização"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "Lembre-se de ajustar a localização da sua casa no mapa, dessa forma as vagas poderão ser indicadas com mais precisão."))), "\n", React.createElement("figure", null, React.createElement(_components.img, {
    src: "/881dd8aba3fb4d38c65328b6c19609ff/experiencias-previas-1.gif",
    alt: "Selecione experiências prévias"
  }), React.createElement("figcaption", null, React.createElement(_components.p, null, "A última etapa de cadastro é selecionar suas experiências prévias. Comece selecionando a área trabalhada anteriormente e depois selecione os cargos correspondentes."))), "\n", React.createElement(_components.p, null, "Agora você já pode começar a utilizar a plataforma e ver suas vagas recomendadas na aba inicial. Você também pode continuar o fluxo de cadastro completo vendo o próximo tutorial de como cadastrar em uma vaga."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
